import React, { useState } from 'react';
import {
  chakra,
  Box,
  Image,
  Flex,
  Icon,
  useColorModeValue,
  Button,
  Stack,
} from '@chakra-ui/react';
import './card.css';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { MdEmail, MdLocationOn, MdCall } from 'react-icons/md';


import {
  RiLinkedinBoxFill,
  RiFacebookBoxFill,
  RiInstagramFill,
} from 'react-icons/ri';
import redhoodLogo from './image/redhood_logo.png';
import twitterLatestLogo from './image/x-social-media-logo-icon.svg';
import { useLocation } from 'react-router';

const Card = props => {

  const path = useLocation();
  console.log("🚀 ~ Card ~ path:", path)

  const [data] = useState(props.data);
  console.log("🚀 ~ Card ~ data:", data)

  const colorMode = useColorModeValue('gray.700', 'gray.200');
  return (
    <Flex
      bg={useColorModeValue('#F9FAFB', 'gray.800')}
      p={{ base: 5, md: 10 }}
      height={'-webkit-fit-content'}
      w="full"
      alignContent={'center'}
      justifyContent="center"
    >
      <Box
        w="sm"
        mx="auto"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="lg"
        rounded={{ base: 'md', md: 'lg' }}
        overflow="hidden"
      >
        <Image
          w="full"
          h={56}
          fit="cover"
          objectPosition="center"
          src={data.avatar_url !== null ? data.avatar_url : 'Loading'}
          alt="avatar"
        />

        <Stack
          pos="relative"
          bottom="7"
          alignItems="center"
          w="full"
          h={'7'}
          color="white"
          background={'gray.100'}
          opacity={'0.6'}
        >
          <Image
            boxSize="50%"
            h={'7'}
            fit="contain"
            src={redhoodLogo}
            alt="redhood_logo"
          />
        </Stack>

        <Box pb={5} px={6}>
          <Flex
            justifyContent={'space-between'}
            color={colorMode}
          >
            <chakra.h1
              fontSize="xl"
              fontWeight="bold"

              color={useColorModeValue('#ae0000', 'gray.200')}
            >
              <a title="Website" href={path.pathname === '/seo' ? 'https://www.redhood.ai' : 'https://www.redhood.ai'}>
                {path.pathname === '/seo' ? (<><span title="First Name">Jayanth</span> <span title="Last Name">Venkatesh</span></>) : data.name}
              </a>
            </chakra.h1>
            <ColorModeSwitcher />
          </Flex>

          {path.pathname === '/seo' ? (<Flex
            justifyContent={'space-between'}
            color={colorMode}
          >
            <chakra.h3
              fontSize="md"
              fontWeight="bold"

              color={colorMode}
            >
              <span title="Title">SEO & Website Development</span>
            </chakra.h3>
          </Flex>) : ''}

          <chakra.p
            fontSize="sm"
            py={2}
            color={useColorModeValue('gray.700', 'white')}
          >
            {path.pathname === '/seo' ? 'redhood.ai provides software training, SEO services, IT & AI solutions.' : data.description}
          </chakra.p>

          {path.pathname !== '/seo' && <Flex
            alignItems="center"
            mt={4}
            color={colorMode}
          >
            <Icon as={MdLocationOn} color="#ae0000" h={6} w={6} mr={2} />

            <chakra.h1 title="Location" px={2} fontSize="sm">
              {data.location}
            </chakra.h1>
          </Flex>}
          <Flex pt={5} flexDirection={'row'} justifyContent={'space-between'}>
            {path.pathname !== '/seo' ? (<Box
              alignItems="center"
              mt={4}
              color={colorMode}
            >
              <a title="Twitter" href="https://twitter.com/redhoodai">
                <Image
                  boxSize="80%"
                  h={'7'}
                  fit="contain"
                  src={twitterLatestLogo}
                  alt="redhood_logo"
                />
              </a>
            </Box>) : (
              <Box
                alignItems="center"
                mt={4}
                color={colorMode}
              >
                <a title="Twitter" href="https://x.com/Jayanthve">
                  {<Image
                    boxSize="80%"
                    h={'7'}
                    fit="contain"
                    src={twitterLatestLogo}
                    alt="redhood_logo"
                  />}
                </a>
              </Box>
            )}

            <Box
              alignItems="center"
              mt={4}
              color={colorMode}
            >
              {path.pathname === '/seo' ? (<a title="Email ID" href="mailto:jayanth@redhood.ai">
                <Icon as={MdEmail} color="#ae0000" h={6} w={6} mr={2} />
              </a>) : (<a title="Email ID" href="mailto:info@redhood.ai">
                <Icon as={MdEmail} color="#ae0000" h={6} w={6} mr={2} />
              </a>)}
            </Box>
            <Box
              alignItems="center"
              mt={4}
              color={colorMode}
            >
              {path.pathname === '/seo' ? <a href="tel:+91 72047 84821">
                <Icon as={MdCall} color="#ae0000" h={6} w={6} mr={2} />
              </a> : <a href="tel:+91 8892453988">
                <Icon as={MdCall} color="#ae0000" h={6} w={6} mr={2} />
              </a>}
            </Box>

            {path.pathname !== '/seo' ? <Box
              alignItems="center"
              mt={4}
              color={colorMode}
            >
              <a title="LinkedIn" href="https://www.linkedin.com/company/redhoodai/">
                <Icon
                  as={RiLinkedinBoxFill}
                  color="#ae0000"
                  h={6}
                  w={6}
                  mr={2}
                />
              </a>
            </Box> : (
              <Box
                alignItems="center"
                mt={4}
                color={colorMode}
              >
                <a title="LinkedIn" href="https://www.linkedin.com/in/jayanth-venkatesh-seo">
                  <Icon
                    as={RiLinkedinBoxFill}
                    color="#ae0000"
                    h={6}
                    w={6}
                    mr={2}
                  />
                </a>
              </Box>
            )}

            {path.pathname !== '/seo' && (<Box
              alignItems="center"
              mt={4}
              color={colorMode}
            >
              <a title="Facebook" href="https://www.facebook.com/redhoodai">
                <Icon
                  as={RiFacebookBoxFill}
                  color="#ae0000"
                  h={6}
                  w={6}
                  mr={2}
                />
              </a>
            </Box>)}
            {path.pathname !== '/seo' && (<Box
              alignItems="center"
              mt={4}
              color={colorMode}
            >
              <a title="Instagram" href="https://www.instagram.com/redhood.ai/">
                <Icon as={RiInstagramFill} color="#ae0000" h={6} w={6} mr={2} />
              </a>
            </Box>)}
          </Flex>
          <Flex
            alignItems="center"
            mt={4}
            color={useColorModeValue('#ae0000', 'gray.200')}
          >
            <Button w={'full'}>
              <a href={path.pathname !== '/seo' ? require('./redhood.ai.vcf') : require('./JaynathV.vcf')} download="user.vcf">
                Save as contact
              </a>
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex >
  );
};

export default Card;
